<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">Empleados</h3>
                  <div class="nk-block-des text-soft">
                    <p>Se encontraron un total de {{ total }} empleados.</p>
                  </div>
                </div><!-- .nk-block-head-content -->
                <div class="nk-block-head-content" v-if="!isSoloVista && !isCliente">
                  <div class="toggle-wrap nk-block-tools-toggle">
                    <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em
                        class="icon ni ni-menu-alt-r"></em></a>
                    <div class="toggle-expand-content" data-content="pageMenu">
                      <ul class="nk-block-tools g-3">
                        <li>
                          <button type="button" class="btn btn-white btn-outline-dark" @click.prevent="generateReporteEmpleados()">
                            <em class="icon ni ni-file-xls"></em><span>Generar Reporte</span>
                          </button>
                        </li>
                        <li>
                          <router-link to="/sistema/empleados/nuevo" class="btn btn-white btn-outline-dark"><em
                              class="icon ni ni-user-add"></em><span>Nuevo Empleado</span>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div><!-- .toggle-wrap -->
                </div><!-- .nk-block-head-content -->
              </div><!-- .nk-block-between -->
            </div><!-- .nk-block-head -->
            <TableGrid :rows="rows" :filters="filters" :total="total" @update="update">
              <template #filters>
                <div class="card-tools between-start overflow-auto">
                  <div v-for="filter in filters" class="mr-3" :key="filter.key">
                    <input v-if="filter.type === 'text'" type="text" class="form-control w-min-100px"
                           :placeholder="filter.label" autocomplete="new" v-model.lazy="filter.value">
                    <SelectServicio v-if="filter.label === 'Servicio'" class="form-control w-min-150px"
                                    v-model:servicio="filter.value" :all="true" placeholder="Servicio">
                    </SelectServicio>
                    <SelectSuperior v-if="filter.label === 'Superior'" class="form-control w-min-150px"
                                    v-model:superior="filter.value" :all="true" placeholder="Superior">
                    </SelectSuperior>
                    <select v-show="!isCliente" v-if="filter.label === 'Estatus'" class="form-control w-min-150px" v-model="filter.value">
                      <option value="" selected disabled>Estatus</option>
                      <option value="0">Todos</option>
                      <option v-for="estatus in ['Pendiente', 'Activo', 'Baja']" :key="estatus">{{ estatus }}</option>
                    </select>
                  </div>
                </div>
              </template>
              <template #header>
                <div class="nk-tb-col"><span class="sub-text">ID</span></div>
                <div class="nk-tb-col"><span class="sub-text">Nombre</span></div>
                <div class="nk-tb-col"><span class="sub-text">Ubicación</span></div>
                <div class="nk-tb-col"><span class="sub-text">Servicio</span></div>
                <div class="nk-tb-col tb-col-sm"><span class="sub-text">Puesto</span></div>
                <div class="nk-tb-col"><span class="sub-text">Superior</span></div>
                <div class="nk-tb-col"><span class="sub-text">Estatus</span></div>
                <div class="nk-tb-col text-center"><span class="sub-text">Detalles</span></div>
                <div class="nk-tb-col text-right" v-if="!isSoloVista && !isCliente"><span class="sub-text">Acciones</span></div>
              </template>
              <template v-slot:row="{row}">
                <div class="nk-tb-col">
                  <span>{{ row.id_empleado }}</span>
                </div>
                <div class="nk-tb-col">
                  <div v-if="!isSoloVista">
                    <router-link :to="'/sistema/empleados/editar/'+row.id_empleado">
                      <span>{{ row.nombre }} {{ row.apellido_paterno }} {{ row.apellido_materno }}</span>
                    </router-link>
                  </div>
                  <div v-else>
                    <span>{{ row.nombre }} {{ row.apellido_paterno }} {{ row.apellido_materno }}</span>
                  </div>
                </div>
                <div class="nk-tb-col">
                  <span>{{ row.ubicacion }}</span>
                </div>
                <div class="nk-tb-col">
                  <span>{{ row.servicio }}</span>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <span>{{ row.puesto }}</span>
                </div>
                <div class="nk-tb-col">
                  <span>{{ row.superior }}</span>
                </div>
                <div class="nk-tb-col">
                  <span :class="['estatus', row.estatus]">{{ row.estatus }}</span>
                </div>
                <div class="nk-tb-col text-center">
                  <button
                    type="button"
                    class="btn btn-round btn-icon btn-primary"
                    data-toggle="modal"
                    data-target="#dlgDetalles"
                    @click="load(row)"
                  >
                  <em class="icon ni ni-info"></em></button
                  >
                </div>
                <div class="nk-tb-col nk-tb-col-tools" v-if="!isSoloVista && !isCliente">
                  <ul class="nk-tb-actions gx-2">
                    <li>
                      <div class="drodown">
                        <a href="#" class="btn btn-sm btn-icon btn-trigger dropdown-toggle" data-toggle="dropdown"><em
                            class="icon ni ni-more-h"></em></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <ul class="link-list-opt no-bdr">
                            <li>
                              <router-link :to="'/sistema/empleados/editar/'+row.id_empleado">
                                <em class="text-primary icon ni ni-user-list"></em><span>Editar</span>
                              </router-link>
                            </li>
                            <li v-if="isAdmin">
                              <a href="#" @click.prevent="generateExcel(row)">
                                <em class="text-success icon ni ni-file-doc"></em><span>Ficha Técnica</span>
                              </a>
                            </li>
                            <li v-if="isAdmin">
                              <a href="#" @click.prevent="generateZIP(row)">
                                <em class="text-secondary icon ni ni-file-zip"></em><span>Descargar Todo</span>
                              </a>
                            </li>
                            <li v-if="isAdmin">
                              <a href="#" @click.prevent="generateGaffete(row)">
                                <em class="text-danger icon ni ni-file-pdf"></em><span>Generar Gaffete</span>
                              </a>
                            </li>
                            <li v-if="isAdmin">
                              <a href="#" @click.prevent="wizardDocumentacion(row)">
                                <em class="text-secondary icon ni ni-setting"></em><span>Generar Expediente</span>
                              </a>
                            </li>
                            <li v-if="isAdmin && row.estatus === 'Pendiente'">
                              <a href="#" @click.prevent="changeEstatus(row)">
                                <em class="text-success icon ni ni-user-check"></em><span>Autorizar</span>
                              </a>
                            </li>
                            <li v-if="isAdmin && row.estatus === 'Activo'">
                              <a href="#" @click.prevent="changeEstatus(row)">
                                <em class="text-warning icon ni ni-user-cross"></em><span>Desautorizar</span>
                              </a>
                            </li>
                            <li v-if="isAdmin && row.estatus === 'Baja'">
                              <a href="#" @click.prevent="changeEstatus(row)">
                                <em class="text-warning icon ni ni-user-check"></em><span>Reactivar</span>
                              </a>
                            </li>
                            <li v-if="isAdmin && row.estatus !== 'Baja'">
                              <a href="#" @click.prevent="remove(row)">
                                <em class="text-danger icon ni ni-user-remove"></em><span>Dar de baja</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </template>
            </TableGrid>
          </div>
        </div>
      </div>

      <div class="modal fade" tabindex="-1" id="dlgDetalles">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <a href="#" class="close" data-dismiss="modal" aria-label="Close">
              <em class="icon ni ni-cross"></em>
            </a>
            <div class="modal-header">
              <h5 class="modal-title">Detalles del empleado: {{ empleado_seleccionado.nombre }}</h5>
            </div>
            <div class="modal-body">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td class="text-right">Nombre</td>
                    <td>{{ empleado_seleccionado.nombre }} {{ empleado_seleccionado.apellido_paterno }} {{ empleado_seleccionado.apellido_materno }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Teléfono</td>
                    <td>{{ empleado_seleccionado.telefono }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Estado civil</td>
                    <td>{{ empleado_seleccionado.estado_civil }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Genero</td>
                    <td>{{ empleado_seleccionado.genero }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Puesto</td>
                    <td>{{ empleado_seleccionado.puesto }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Servicio</td>
                    <td>{{ empleado_seleccionado.servicio }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Superior</td>
                    <td>{{ empleado_seleccionado.superior }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">Ubicación</td>
                    <td>{{ empleado_seleccionado.ubicacion }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content @e -->
    <!-- Wizard Documentacion -->
    <WizardDocumentacion :empleado="empleado" :show="showWizard" @close="showWizard = false"></WizardDocumentacion>
    <!-- Wizard Documentacion @e -->
  </AppView>
</template>

<script>
import {reactive, ref, toRefs, defineComponent} from "vue";
import AppView from "@/components/sistema/AppView";
import TableGrid from "@/components/TableGrid";
import Swal from "sweetalert2";
import {useApi} from "@/use/useApi";
import useAuth from "@/providers/auth";
import {useRouter} from "vue-router";
import SelectServicio from "@/components/globales/SelectServicio";
import SelectSuperior from "@/components/globales/SelectSuperior";
import WizardDocumentacion from "@/components/globales/WizardDocumentacion";

export default defineComponent({
  name: "Empleados",
  components: {WizardDocumentacion, SelectSuperior, SelectServicio, TableGrid, AppView},
  setup() {
    const {isAdmin, isSoloVista, isCliente} = useAuth();
    const router = useRouter();

    const state = reactive({
      rows: [],
      showWizard: false,
      empleado: null,
      empleado_seleccionado: {},
      total: 1
    });
    const filters = ref([
      {
        key: 'id_empleado',
        label: 'ID',
        value: '',
        type: 'text'
      }, {
        key: 'nombre',
        label: 'Nombre',
        value: '',
        type: 'text'
      }, {
        key: 'apellido_paterno',
        label: 'Apellido paterno',
        value: '',
        type: 'text'
      }, {
        key: 'apellido_materno',
        label: 'Apellido materno',
        value: '',
        type: 'text'
      }, {
        key: 'id_servicio',
        label: 'Servicio',
        value: '',
        type: 'select'
      }, {
        key: 'id_superior',
        label: 'Superior',
        value: '',
        type: 'select'
      }, {
        key: 'puesto',
        label: 'Puesto',
        value: '',
        type: 'text'
      }, {
        key: 'estatus',
        label: 'Estatus',
        value: '',
        type: 'select'
      }
    ]);

    const {loading, make} = useApi();

    async function update(config) {
      loading.message = 'Obteniendo información';
      let {data} = await make('empleados/get-all', config);
      if (data === null) {
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if (data.error) {
          Swal.fire('Error', data.message, 'error');
        } else {
          state.rows = data.rows;
          state.total = data.total;
        }
      }
    }

    function getNuevoEstatus(estatus){
      switch (estatus) {
        case "Activo": case "Baja":
          return 'Pendiente';
        case "Pendiente":
          return "Activo";
      }
    }

    async function changeEstatus(empleado) {
      let nuevo_estatus = getNuevoEstatus(empleado.estatus);
      Swal.fire({
        title: 'Cambiar estatus empleado',
        text: '¿Estás seguro que deseas cambiar a ' + nuevo_estatus + ' al empleado ' + empleado.nombre + ' ' + empleado.apellido_paterno + ' ' + empleado.apellido_materno + '?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar'
      }).then(async res => {
        if (res.isConfirmed) {
          loading.message = 'Procesando información';
          let model = {id_empleado: empleado.id_empleado, estatus: nuevo_estatus};
          let {data} = await make('empleados/change-estatus', {model});
          if (data === null) {
            Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
          } else {
            if (data.error) {
              Swal.fire('Error', data.message, 'error');
            } else {
              router.go();
            }
          }
        }
      });
    }

    async function remove(empleado) {
      Swal.fire({
        title: 'Dar de baja empleado',
        text: '¿Estás seguro que deseas dar de baja a ' + empleado.nombre + ' ' + empleado.apellido_paterno + ' ' + empleado.apellido_materno + '?',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#e85347'
      }).then(async res => {
        if (res.isConfirmed) {
          router.replace({path: '/sistema/empleados/editar/' + empleado.id_empleado, query: {baja: 'si'}});
        }
      });
    }

    async function generateReporteEmpleados() {
      Swal.fire({
        title: 'Generar reporte',
        text: '¿Estás seguro que deseas generar el reporte de los empleados?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#1ee0ac'
      }).then(async res => {
        if (res.isConfirmed) {
          loading.message = 'Procesando información';

          let {data} = await make('empleados/excel/reporte-empleados', {});

          if (data === null) {
            Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
          } else {
            if (data.error) {
              Swal.fire('Error', data.message, 'error');
            } else {
              location.href = data.url;
            }
          }
        }
      });
    }

    async function generateExcel(empleado) {
      Swal.fire({
        title: 'Generar ficha técnica',
        text: '¿Estás seguro que deseas generar la ficha técnica del empleado ' + empleado.nombre + ' ' + empleado.apellido_paterno + ' ' + empleado.apellido_materno + '?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#1ee0ac'
      }).then(async res => {
        if (res.isConfirmed) {
          loading.message = 'Procesando información';
          let model = {id_empleado: empleado.id_empleado};
          let {data} = await make('empleados/excel/ficha-tecnica', {model});
          if (data === null) {
            Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
          } else {
            if (data.error) {
              Swal.fire('Error', data.message, 'error');
            } else {
              location.href = data.url;
            }
          }
        }
      });
    }

    async function generateZIP(empleado) {
      Swal.fire({
        title: 'Generar documentación',
        text: '¿Estás seguro que deseas generar la documentación del empleado ' + empleado.nombre + ' ' + empleado.apellido_paterno + ' ' + empleado.apellido_materno + '?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#1ee0ac'
      }).then(async res => {
        if (res.isConfirmed) {
          loading.message = 'Procesando información';
          let model = {id_empleado: empleado.id_empleado};
          let {data} = await make('empleados/zip/documentacion', {model});
          if (data === null) {
            Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
          } else {
            if (data.error) {
              Swal.fire('Error', data.message, 'error');
            } else {
              location.href = data.url;
            }
          }
        }
      });
    }

    async function generateGaffete(empleado) {
      Swal.fire({
        title: 'Generar Gaffete',
        text: '¿Estás seguro que deseas generar el Gaffete del empleado ' + empleado.nombre + ' ' + empleado.apellido_paterno + ' ' + empleado.apellido_materno + '?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#1ee0ac'
      }).then(async res => {
        if (res.isConfirmed) {
          loading.message = 'Procesando información';
          let model = {id_empleado: empleado.id_empleado};
          let {data} = await make('empleados/pdf/gaffete', {model});
          if (data === null) {
            Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
          } else {
            if (data.error) {
              Swal.fire('Error', data.message, 'error');
            } else {
              window.open(data.url, '_blank').focus();
            }
          }
        }
      });
    }

    async function load(empleado){
      state.empleado_seleccionado = empleado;
    }

    async function wizardDocumentacion(empleado) {
      state.empleado = empleado;
      state.showWizard = true;
    }

    return {...toRefs(state), isAdmin, isSoloVista, isCliente, filters, remove, update, changeEstatus, generateReporteEmpleados, generateExcel, generateZIP, generateGaffete, wizardDocumentacion, load}
  }
});
</script>

<style scoped>

</style>