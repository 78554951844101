<template>
  <div class="wizard-documentacion" tabindex="0" @keyup.esc="close" v-if="show">
    <div class="wizard-container">
      <button class="btn-close btn btn-outline-danger" @click.prevent="close"><em class="ni ni-cross"></em></button>
      <h4>GENERAR EXPEDIENTE DEL EMPLEADO <span class="font-italic">"{{empleado.nombre}} {{empleado.apellido_paterno}} {{empleado.apellido_materno}}"</span></h4>
      <div class="card card-bordered card-stretch mt-4">
        <div class="card-inner">
          <h6>Documentación</h6>
          <div class="row mt-3">
            <div class="col-sm-4 col-lg-3 mb-3 pl-4 d-flex justify-content-between" v-for="field in fields.documentacion" :key="field.key">
              <label class="form-label">{{field.label}}</label>
              <Toggle class="ml-2" v-model="field.checked" />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-4">
        <button class="btn btn-primary" @click.prevent="generate">Generar Expediente</button>
      </div>
    </div> <!-- Wizard Container @e -->
  </div> <!-- Wizard Documentacion @e -->
</template>

<script>
import {defineComponent, ref} from "vue";
import Swal from "sweetalert2";
import {useApi} from "@/use/useApi";
import Toggle from '@vueform/toggle'

export default defineComponent({
  name: "WizardDocumentacion",
  components: {
    Toggle
  },
  props: {
    empleado: {
      type: Object,
      default: null
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}){

    const { loading, make } = useApi();
    const fields = ref({
      documentacion: []
    });

    async function getFields(){
      loading.message = 'Obteniendo servicios';
      let {data} = await make('generales/wizard/get-fields', {});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          fields.value = data.fields;
        }
      }
    }

    async function generate(){
      Swal.fire({
        title: 'Generar documentación',
        text: '¿Estás seguro que deseas generar la documentación del empleado ' + props.empleado.nombre + ' ' + props.empleado.apellido_paterno + ' ' + props.empleado.apellido_materno + '?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#1ee0ac'
      }).then(async res => {
        if (res.isConfirmed) {
          loading.message = 'Procesando información';
          let model = {id_empleado: props.empleado.id_empleado};
          let {data} = await make('empleados/zip/documentacion-by-fields', {model, fields: fields.value});
          if (data === null) {
            Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
          } else {
            if (data.error) {
              Swal.fire('Error', data.message, 'error');
            } else {
              location.href = data.url;
            }
          }
        }
      });
    }

    function close(){
      emit('close');
    }

    getFields();

    return {fields, close, generate};
  }
})
</script>

<style scoped>
  .wizard-documentacion {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(0,0,0, 0.6);
    width: 100%;
    height: 100%;
  }
  .wizard-container {
    position: relative;
    width: 90%;
    height: 90%;
    background: #ffffff;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 2rem;
    overflow: auto;
  }

  .btn-close {
    position: absolute;
    top: 1rem;
    right: 2rem;
  }
</style>